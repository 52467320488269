<template>
    <div id="app">
        <div
            v-if="isMaintenance"
            class="hero-image w-screen h-screen"
        >
            <div class="hero-text">
                <h1 class="text-white text-2xl">
                    {{ themeConfig.maintenanceMessage }}
                </h1>
                <button
                    @click.prevent="logout"
                    class="lg:w-2/5 button  hover:border-twitter text-body-text inline-block  mt-2 ml-0 rounded-lg font-bold"
                >
                    Exit
                </button>
            </div>
        </div>

        <div v-else>
            <HandleServerTime v-if="appReady" />

            <transition
                name="page-fade"
                mode="out-in"
            >
                <HandleAppUpdates v-if="appReady" />
            </transition>

            <transition
                name="page-fade"
                mode="out-in"
            >
                <HandleAppAlerts v-if="appReady" />
            </transition>
            <transition
                name="page-fade"
                mode="out-in"
            >
                <HandleRedirects v-if="appReady" />
            </transition>

            <transition
                name="page-fade"
                mode="out-in"
            >
                <Header v-if="appReady && enableHeader" />
            </transition>

            <main class="site-content bg-site-color">
                <transition
                    name="page-fade"
                    mode="out-in"
                >
                    <Spinners
                        v-if="loadingSignedUrl"
                        class="py-8"
                    />
                    <router-view
                        v-else
                        :key="route.params.id || route.name"
                    />
                </transition>
            </main>

            <transition
                name="page-fade"
                mode="out-in"
            >
                <Footer v-if="appReady && enableFooter" />
            </transition>

            <chat-helpdesk v-if="!chatNotAllowedInRoute && isChatEnabled"></chat-helpdesk>

            <session-evaluation v-if="appReady && sessionEvalsEnabled" />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import screenfull from "screenfull";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ChatHelpdesk from "@/components/ChatHelpdesk";
import HandleServerTime from "@/components/HandleServerTime";
import HandleAppUpdates from "@/components/HandleAppUpdates";
import HandleAppAlerts from "@/components/HandleAppAlerts";
import HandleRedirects from "@/components/HandleRedirects";
import SessionEvaluation from "@/components/SessionEvaluation";
import Spinners from "@/components/utilities/Spinners.vue";

import { getModule } from "vuex-module-decorators";

import presenceVuexModule from "@/store/vuex-modules/presence";
const presenceStore = getModule(presenceVuexModule);

import signedUrlVuexModule from "@/store/vuex-modules/signed-url";
const signedUrlStore = getModule(signedUrlVuexModule);

export default {
    name: "Home",
    components: {
        Header,
        Footer,
        ChatHelpdesk,
        HandleServerTime,
        HandleAppUpdates,
        SessionEvaluation,
        Spinners,
        HandleAppAlerts,
        HandleRedirects
    },
    data() {
        return {
            appReady: false
        };
    },
    computed: {
        ...mapState([
            "enableHeader",
            "enableFooter",
            "route",
            "themeConfig",
            "authWantsRoute"
        ]),
        ...mapGetters([
            "user",
            "isChatEnabled",
            "favicon",
            "getPageOptions",
            "userInfo",
            "isAuthorized",
            "isAuthenticated",
            "authConfig",
            "authorizer"
        ]),
        isMaintenance() {
            return this.inMaintenance();
        },
        isGate() {
            return this.route.name === "Gate" ? true : false;
        },
        isSuperUser() {
            return this.$store.getters.isSuperUser;
        },
        chatNotAllowedInRoute() {
            // TODO this should be part of the `isChatEnabled` logic but tyescript is being problematic
            const disableOnRoutes = ["MeetingView", "MeetingDeveloper"];

            return Boolean(disableOnRoutes.includes(this.route.name));
        },
        sessionEvalsEnabled() {
            const pageOptions = this.getPageOptions("sessionEvals");

            return pageOptions && "enabled" in pageOptions
                ? pageOptions.enabled
                : true;
        },
        loadingSignedUrl() {
            return signedUrlStore.loadingSignedUrl;
        }
    },
    watch: {
        authWantsRoute() {
            const route = this.authWantsRoute;
            this.goToRouteAuthWants(route);
        }
    },
    created() {
        const route = this.authWantsRoute;

        // unlike the watcher for `authWantsRoute`
        // we only want to do something here if we start out with a value for `authWantsRoute`
        if (route) {
            this.goToRouteAuthWants(route);
        }

        this.handleAuth().then(() => {
            this.init();
        });
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.deletePresence);
    },
    methods: {
        ...mapMutations(["setIsFullScreen", "setLoginError"]),
        ...mapActions(["handleAppSettings", "loginUser"]),
        init() {
            /**
             * NOTE about quering API data in this component.
             */
            // Step 1. Try retrieving api data when app loads.
            this.handleAppSettings();
            this.doWeNeedToRequestSignedUrl().then((answer) => {
                const routerHasRequestedSignedUrl =
                    signedUrlStore.routerHasRequestedSignedUrl;
                if (answer && !routerHasRequestedSignedUrl) {
                    signedUrlStore.getAndSetSignedUrl();
                }
            });

            if (this.favicon) {
                // TODO
                // Use SVG
                // https://css-tricks.com/svg-favicons-and-all-the-fun-things-we-can-do-with-them/
                // ... and we should really be using enviornment variables
                // this current setup is temporary and will likely break the favicon on some devices.
                document
                    .querySelectorAll('link[rel="icon"]')
                    .forEach((item) => {
                        if (this.favicon.includes(".svg")) {
                            item.type = "image/svg+xml";
                        }
                        item.href = `/favicon/${this.favicon}`;
                    });
            }

            if (screenfull.isEnabled) {
                screenfull.on("change", () => {
                    this.setIsFullScreen();
                });
            }

            window.addEventListener("beforeunload", this.deletePresence);
        },
        createLoginLog() {
            const logData = {
                type: "LoginUser"
            };

            this.$store
                .dispatch("appendLogEntry", logData)
                .then((res) => console.log(res))
                .catch((err) =>
                    console.log(
                        `Error logging user log in event: ${logData}, ${err}, ${err.stack}`
                    )
                );
        },
        handleOneSignal() {
            const settings = this.themeConfig.oneSignalSettings;
            const oneSignalId = settings && settings.id ? settings.id : "";
            const oneSignalHost =
                settings && settings.hostname ? settings.hostname : "";
            // TODO: we will just need to find a way to put this on config
            if (
                window.OneSignal &&
                oneSignalId != "" &&
                window.location.hostname === oneSignalHost
            ) {
                window.OneSignal.push(function () {
                    window.OneSignal.init({
                        appId: oneSignalId
                    });
                });
            }
        },
        goToRouteAuthWants(route) {
            const to = route ? route : "/";
            this.$router.push(to);
        },
        async deletePresence() {
            const route = this.route;
            try {
                await presenceStore.deleteRoutePresence(route);
            } catch (error) {
                // n/a
            }
        },
        async doWeNeedToRequestSignedUrl() {
            let signedUrlAddress;
            let signedUrlIsExpired = true;
            const isAuthorized = this.isAuthorized;

            try {
                signedUrlIsExpired = await signedUrlStore.IsSignedUrlExpired();
                signedUrlAddress = signedUrlStore.signedUrlAddress;
            } catch (error) {
                // n/a
            }

            return Boolean(
                (signedUrlIsExpired || !signedUrlAddress) && isAuthorized
            );
        },
        async handleAuth() {
            const ourLocation = new URL(location.href);

            // support for custom authentication (see CHA authConfig)
            // if tokenParams is set in authConfig, we will use those to build
            // the request to getAccessToken
            // Otherwise, we will use "code" which is the standard field name for OAuth
            const tokenParams = {};
            const tokenParamList = this.authConfig.tokenParams || ["code"];
            try {
                for (const param of tokenParamList) {
                    const locationParam = ourLocation.searchParams.get(param);
                    if (locationParam) {
                        tokenParams[param] = locationParam;
                    }
                }
            } catch (err) {
                console.error(err);
            }
            if (Object.keys(tokenParams).length && !this.isAuthenticated) {
                try {
                    const authConfig = this.authConfig;
                    const tokenResponse = await this.authorizer.getAccessToken(
                        authConfig,
                        tokenParams
                    );
                    if (!window.localStorage.getItem("storedPath")) {
                        window.localStorage.setItem("storedPath", "/");
                    }
                    // console.log("tokenResponse", tokenResponse);
                    await this.loginUser(tokenResponse);
                    this.createLoginLog();
                } catch (err) {
                    console.error(err);
                    this.setLoginError(err);
                } finally {
                    // console.log("handleAuth done");
                }
            }

            this.appReady = Boolean(this.isAuthenticated);

            return true;
        },
        inMaintenance() {
            if (this.isGate) {
                return false;
            } else {
                return this.themeConfig.isSiteActive.inMaintenance &&
                    !this.isSuperUser
                    ? true
                    : false;
            }
        },
        logout() {
            this.$store.dispatch("logout");
        }
    }
};
</script>
<style scoped>
.hero-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("assets/images/maintenance.jpeg");
    height: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}
</style>
