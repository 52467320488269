import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { AttendeeBase } from "@/types/interfaces";
import { getApiClient } from "@/services/api";
import { config } from "vuex-module-decorators";

const endpoint = `userinfo`;

// Set rawError to true by default on all @Action decorators
config.rawError = true;

/**
 * NOTE: not using dynamic module - this module is being used in `App.vue` where for some reason dynamic modules throw an error.
 */
@Module
export default class UserinfoModule extends VuexModule {
    // `userApi` will eventually deprecate `user` which is in the top level of the vuex state
    // the idea is to remove / minimize user information in the auth token.
    userApi: AttendeeBase = {};

    get userInfo() {
        return this.userApi;
    }

    get myBespeakeId() {
        return this.userApi.id;
    }

    get isSuperUser() {
        return Boolean(this.userApi.superuser);
    }

    @Mutation
    setUserinfo(payload: AttendeeBase = {}) {
        this.userApi = payload;
    }

    @Action({ commit: "setUserinfo" })
    async getUserinfo() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    },
                    // isUserInfoEndpoint header is so we can check for this endpoint in the interceptor
                    // without having to hardcode the path in the interceptor conditional.
                    // It would be better if we could set a custom attr in the request
                    // but I don't think it is possible in axios.
                    params: {
                        isUserInfoEndpoint: true
                    }
                })
                .then((response) => {
                    this.context.dispatch(
                        "setupGtmUserProperties",
                        response.data
                    );
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({})
    async setupGtmUserProperties(payload: AttendeeBase = {}) {
        window.dataLayer = window.dataLayer || [];

        const userData = {
            event: "set_user_id",
            attendeeId: payload.id ? payload.id : "(not set)",
            attendeeType: payload.attendeeType
                ? payload.attendeeType
                : "(not set)"
        };

        window.dataLayer.push(userData);
    }
}
