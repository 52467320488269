<template>
    <transition-group
        v-if="renderMe"
        name="feed-transition"
        tag="div"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOut"
        role="complementary"
        aria-label="Bespeake Helpdesk"
    >
        <div
            v-if="showChat"
            id="chat-root"
            key="chat-wrapper"
            class="fixed bottom-0 right-0 mb-4 mr-4 text-base z-50"
        >
            <div
                class="rounded-lg overflow-hidden flex items-center justify-center bg-white connect-customer-interface sc-gipzik eYCHsU my-4"
            >
                <spinners v-if="!chatIsActive" key="spinner" />

                <div
                    v-else
                    key="chat-body"
                    class="relative flex flex-col h-full w-full"
                >
                    <div
                        class="bg-primary px-4 py-2 text-white text-center text-lg flex justify-between"
                    >
                        <div class="flex items-center flex-1">
                            <button
                                @click.prevent="getTranscript"
                                class="mr-2 text-sm text-white bg-transparent border-white rounded border py-2 px-3 invisible"
                            >
                                <font-awesome-icon
                                    aria-hidden="true"
                                    icon="envelope"
                                />
                                <span class="sr-only"
                                    >Email me the transcript</span
                                >
                            </button>
                        </div>
                        <div class="w-2/3">
                            <h2 class="font-light text-white m-0">Helpdesk</h2>
                            <!-- <p id="chatDescription" class="m-0">You are now chatting with Ray</p> -->
                        </div>
                        <div aria-hidden="true" class="flex-1">&nbsp;</div>
                    </div>

                    <div
                        ref="chat-scroll"
                        class="order-2 flex-1 bg-white fix-scroll-to-bottom overflow-y-auto overscroll-contain"
                        tabindex="0"
                    >
                        <div v-for="item in messages" :key="item.Id">
                            <!-- event notice -->
                            <div
                                v-if="'EVENT' === item.Type"
                                class="px-4 my-6 text-center chat__event-description"
                            >
                                <div
                                    v-if="
                                        item.ContentType.includes(
                                            'participant.joined'
                                        ) ||
                                        item.ContentType.includes(
                                            'participant.left'
                                        )
                                    "
                                >
                                    <span v-if="item.DisplayName">
                                        {{ item.DisplayName }}
                                    </span>
                                    <span v-else>Someone</span>

                                    <span
                                        v-if="
                                            item.ContentType.includes(
                                                'participant.joined'
                                            )
                                        "
                                    >
                                        has joined the chat
                                    </span>
                                    <span v-else> has left the chat </span>
                                </div>

                                <div
                                    v-else-if="
                                        item.ContentType.includes('chat.ended')
                                    "
                                >
                                    The chat has ended
                                </div>

                                <div v-else>
                                    Chat Event ContentType:
                                    {{ item.ContentType }}
                                </div>
                            </div>

                            <!-- incoming message -->
                            <div
                                v-else-if="'MESSAGE' === item.Type"
                                class="sc-VigVT chat__message-wrapper px-4 my-6"
                            >
                                <div
                                    class="sc-jTzLTM overflow-hidden djefvl flex text-sm justify-between"
                                >
                                    <div
                                        class="sc-fjdhpX chat_message-from font-bold"
                                    >
                                        <span
                                            v-if="
                                                'SYSTEM' ===
                                                item.ParticipantRole
                                            "
                                        >
                                            System Message
                                        </span>
                                        <span v-else>
                                            {{ item.DisplayName }}
                                        </span>
                                    </div>
                                    <div class="sc-jzJRlG chat_message-time">
                                        <!-- 6/9/2020, 1:46 PM -->
                                        {{
                                            MgFormatISODateTime(
                                                item.AbsoluteTime,
                                                "time"
                                            )
                                        }}
                                    </div>
                                </div>
                                <div
                                    :class="{
                                        'chat__chat-message--outgoing':
                                            'CUSTOMER' === item.ParticipantRole,
                                        'chat__chat-message--incoming':
                                            'CUSTOMER' !== item.ParticipantRole
                                    }"
                                    class="sc-cSHVUG chat__chat-message mt-2"
                                >
                                    <span class="Linkify">
                                        <div>
                                            <span>{{ item.Content }}</span>
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <!-- other, this is here for debugging -->
                            <div v-else class="px-4 my-6">
                                <div>Chat Message Type: {{ item.Type }}</div>
                                <div>
                                    Chat Message ContentType:
                                    {{ item.ContentType }}
                                </div>
                            </div>
                        </div>

                        <!-- typing ellipses -->
                        <div
                            v-if="agentIsTyping"
                            class="chat__message-wrapper px-4 my-6"
                        >
                            <div
                                class="chat__chat-message--incoming chat__chat-message-ellipses chat__chat-message mt-2 flex"
                            >
                                <div
                                    class="m-1 rounded-full h-1 w-1 chat__chat-message-ellipses-item"
                                ></div>
                                <div
                                    class="m-1 rounded-full h-1 w-1 chat__chat-message-ellipses-item chat__chat-message-ellipses-item-middle"
                                ></div>
                                <div
                                    class="m-1 rounded-full h-1 w-1 chat__chat-message-ellipses-item chat__chat-message-ellipses-item-end"
                                ></div>
                            </div>
                        </div>

                        <div class="fix-scroll-to-bottom__anchor"></div>
                    </div>

                    <form
                        @submit.prevent="sendChat"
                        class="bg-white border-t border-b order-3 relative"
                    >
                        <input
                            v-model="message"
                            @keyup="sendTypingEvent"
                            type="text"
                            placeholder="Type a message"
                            class="p-4 block w-full"
                        />
                    </form>

                    <div class="chat-footer order-4">
                        <div
                            class="p-4 py-6 flex items-center justify-center bg-muted"
                        >
                            <button
                                class="button pill-button is-primary font-bold"
                                @click.prevent="endChat"
                            >
                                End chat
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <button
                    class="text-3xl block leading-none bg-transparent p-0 text-accent -mr-1"
                    @click="toggleChat"
                >
                    <svg-chat-hide
                        class="block m-0"
                        aria-hidden="true"
                        focusable="false"
                    ></svg-chat-hide>
                    <span class="sr-only">Hide Chat</span>
                </button>
            </div>
        </div>
        <!-- #chat-root -->

        <div
            v-else
            key="chat-trigger"
            class="fixed bottom-0 right-0 mb-4 mr-4 z-50"
        >
            <button
                @click="initializeChat"
                class="text-3xl block leading-none bg-transparent p-0 text-accent"
            >
                <svg-chat-connect
                    class="block m-0"
                    aria-hidden="true"
                    focusable="false"
                ></svg-chat-connect>
                <span class="sr-only">Start Chat</span>
            </button>
        </div>
    </transition-group>
</template>

<script>
// TODO
// Currently need to update allowed origins in two places:
// https://matrix-group.atlassian.net/browse/VMP-388

import "amazon-connect-chatjs";
import throttle from "lodash/throttle";
import Spinners from "@/components/utilities/Spinners.vue";
import svgChatHide from "@/components/svg/svg-chat-hide";
import svgChatConnect from "@/components/svg/svg-chat-connect";
import { mapGetters } from "vuex";

import { getModule } from "vuex-module-decorators";
import helpdeskchatVuexModule from "@/store/vuex-modules/helpdeskchat";
const helpdeskchatStore = getModule(helpdeskchatVuexModule);

/**
 * `searchAttendeeData.ts` makes the following import
 * import { Lambda } from "aws-sdk";
 * For some reason that `Lambda` import breaks window.AWS.ConnectParticipant
 * When this component loads window.AWS.ConnectParticipant is available
 * Don't have time to find a proper solution.
 * For now we will save this method as `window.MgConnectParticipant` while it is available
 * And use that to restore window.AWS.ConnectParticipant when `initializeChat` is called.
 */
window.MgConnectParticipant = window.AWS.ConnectParticipant;

export default {
    name: "ChatHelpdesk",
    components: {
        svgChatHide,
        svgChatConnect,
        Spinners
    },
    data() {
        return {
            renderMe: true,
            agentIsTyping: false,
            agentIsTypingTimeout: null,
            showChat: false,
            chatIsActive: false,
            message: "",
            messages: []
        };
    },
    computed: {
        ...mapGetters([
            "userInfo",
            "helpdeskContactFlowId",
            "helpdeskInstanceId",
            "helpdeskInstanceRegion"
        ])
    },
    watch: {
        showChat() {
            if (this.showChat) {
                this.scrollChat();
            }
        }
    },
    created() {
        window.mgChatSession = null;

        // https://github.com/amazon-connect/amazon-connect-chatjs/blob/master/README.md#api
        const chatConfig = {
            region: this.helpdeskInstanceRegion
        };

        if (!window.MgConnectParticipant) {
            this.renderMe = false;
        }

        if (window.connect) {
            window.connect.ChatSession.setGlobalConfig(chatConfig);
        } else {
            console.error("chatjs not available.");
        }

        window.addEventListener("beforeunload", this.handleWindowUnload);
    },
    beforeDestroy() {
        // remove listener `beforeunload`
        window.removeEventListener("beforeunload", this.handleWindowUnload);

        // end chat - see TODO in `handleWindowUnload`
        this.endChat();
    },
    methods: {
        isMessageFromPerson(message) {
            if (!message) return false;

            const name = message?.DisplayName;

            if (
                !name ||
                [
                    "prod",
                    "$LATEST",
                    "AI Assistant",
                    "SYSTEM_MESSAGE",
                    "System Message"
                ].includes(name)
            ) {
                return false;
            }

            return true;
        },
        handleWindowUnload(event) {
            // TODO AWS provides api code which tries to get previous chat
            // need to update the api we are currently using with this and test:
            // https://github.com/amazon-connect/amazon-connect-chat-ui-examples/blob/master/cloudformationTemplates/asyncCustomerChatUX/js/startChatContact.js
            // for now we will end the chat when the tab is closed
            this.endChat(event);
        },
        toggleChat() {
            this.showChat = !this.showChat;
        },
        initializeChat() {
            if (!window.AWS.ConnectParticipant) {
                window.AWS.ConnectParticipant = window.MgConnectParticipant;
            }

            const initiateChatRequest = {
                ParticipantDetails: {
                    DisplayName: "Meeting Attendee"
                },
                ContactFlowId: this.helpdeskContactFlowId,
                InstanceId: this.helpdeskInstanceId
            };

            if (this.userInfo?.name) {
                initiateChatRequest.ParticipantDetails.DisplayName =
                    this.userInfo.name;
            }

            this.showChat = true;

            if (!this.chatIsActive) {
                const payload = JSON.stringify(initiateChatRequest);
                helpdeskchatStore
                    .startChat(payload)
                    .then((response) => {
                        this.successHandler(response);
                    })
                    .catch((error) => {
                        this.failureHandler(error);
                    });
            }
        },
        registerSessionEvents() {
            window.mgChatSession.connect().then(
                (response) => {
                    console.log(
                        "Successful connection: " + JSON.stringify(response)
                    );
                    return response;
                },
                (error) => {
                    console.log(
                        "Unsuccessful connection " + JSON.stringify(error)
                    );
                    this.chatIsActive = false;
                    return Promise.reject(error);
                }
            );

            window.mgChatSession.onConnectionEstablished((data) => {
                console.log("Connection Established!", data);
            });

            window.mgChatSession.onMessage((message) => {
                // console.log('Received message: ' + JSON.stringify(message))
                this.messages.push(message.data);

                if (this.isMessageFromPerson(message.data)) {
                    this.agentIsTyping = false;
                    clearTimeout(this.agentIsTypingTimeout);
                }
            });

            window.mgChatSession.onTyping((typingEvent) => {
                // console.log('Received typing event: ' + JSON.stringify(typingEvent))

                if ("CUSTOMER" !== typingEvent.data.ParticipantRole) {
                    this.agentIsTyping = true;
                    this.agentIsTypingTimeout = setTimeout(() => {
                        this.agentIsTyping = false;
                    }, 5000);
                }
            });

            window.mgChatSession.onConnectionBroken((data) => {
                console.log("Connection broken.", data);
                this.chatIsActive = false;
            });
        },
        successHandler(response) {
            if (!window.connect) {
                console.error("Connect instance not available.");
                return;
            }

            this.chatIsActive = true;
            window.mgChatSession = window.connect.ChatSession.create({
                chatDetails: response.data.startChatResult,
                type: "CUSTOMER"
            });

            this.registerSessionEvents();
        },
        failureHandler(error) {
            this.chatIsActive = false;

            console.error("API error:", error);
        },
        sendChat() {
            window.mgChatSession.controller.sendMessage({
                message: this.message,
                contentType: "text/plain"
            });
            this.message = "";
            this.scrollChat();
        },
        scrollChat() {
            this.$nextTick(() => {
                const chatScroll = this.$refs["chat-scroll"];
                // scroll to bottom of chat
                if (chatScroll) {
                    chatScroll.scrollTo(null, chatScroll.scrollHeight);
                }
            });
        },
        sendTypingEvent: throttle(
            (event) => {
                const ignoreKeys = [
                    "Enter",
                    "Escape",
                    "Backspace",
                    "Meta",
                    "Alt",
                    "Control"
                ];

                if (!window.mgChatSession || ignoreKeys.includes(event.key)) {
                    return;
                }

                window.mgChatSession.controller.sendEvent({
                    contentType:
                        "application/vnd.amazonaws.connect.event.typing"
                });
            },
            5000,
            { trailing: false }
        ),
        endChat() {
            if (window.mgChatSession && this.chatIsActive) {
                window.mgChatSession.controller
                    .disconnectParticipant()
                    .finally(() => {
                        this.resetChatUi();
                    });
            } else {
                this.resetChatUi();
            }
        },
        getTranscript() {
            if (!window.mgChatSession) return;

            window.mgChatSession
                .getTranscript({
                    scanDirection: "BACKWARD",
                    sortOrder: "ASCENDING",
                    maxResults: 100
                })
                .then((response) => {
                    console.log(response.data.Transcript);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        emailTranscript() {
            // Todo
            // https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/ses-examples-sending-email.html
        },
        resetChatUi() {
            this.message = "";
            this.messages = [];
            this.showChat = false;
            this.chatIsActive = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/components/chat";
</style>
