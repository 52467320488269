const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.02
};
const oberver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add("inside-viewport");
        }
        // Do not remove inside-viewport after it has been added.
        // the animation should only happen once.
    });
}, options);

export default {
    bind(el: Element) {
        el.classList.add("outside-viewport");
        oberver.observe(el);
    }
};
