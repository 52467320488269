import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { config } from "vuex-module-decorators";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

// This module is only for functionality related to the following endpoint:
const endpoint = "notifications";

/**
 * NOTE: not using dynamic module - this is being used in component `navbar.vue` where for some reason the dynamic module throws an error.
 */
@Module
export default class NotificationsModule extends VuexModule {
    networkingNotifications = 0;

    get networkingNotificationsUnseen() {
        return this.networkingNotifications;
    }

    @Mutation
    setNetworkingNotifications(payload: number) {
        this.networkingNotifications = payload;
    }

    @Action({ commit: "setNetworkingNotifications" })
    getNetworkingNotifications() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/networking`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    const unseen = response.data?.unseen || 0;
                    return resolve(unseen);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setNetworkingNotifications" })
    clearNetworkingNotifications() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .delete(`/${endpoint}/networking`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    const unseen = response.data?.unseen || 0;
                    return resolve(unseen);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
