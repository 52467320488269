/**
 * Using traditional module. This code needs to load / run asap.
 */

import { getApiClient } from "@/services/api";
import { UserLogData, LogInput } from "@/types/interfaces";

// This module is only for functionality related to the following endpoint:
const endpoint = "log";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async appendLogEntry(
        /**
         * Vuex doesn't type its rootGetters, so we have to leave this as any.
         */
        // eslint-disable-next-line
        { rootGetters }: { rootGetters: Record<string, any> },
        logEntry: LogInput
    ): Promise<Record<string, string> | string> {
        /**
         * TODO IDEA: Don't track matrix users at all, and don't track log entries on localhost.
         *
         * For production, this means we wouldn't need to filter Matrix users out of reports.
         * For development, this means we could still track log entries on dev but only if we intentionally
         *   log in as real users and not Matrix users.
         */
        const token = rootGetters.idToken;
        const userInfo = rootGetters.userInfo;

        // once we merge VMP-2847/cha-authorization-flow into master, remove the OR condition and this comment
        const userId = userInfo.id || rootGetters.user.id;

        const userData =
            logEntry.type != "LoginUser"
                ? ({
                    uid: userId,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    email: userInfo.email,
                    companyName: userInfo.companyName
                } as UserLogData)
                : ({
                    uid: userId
                } as UserLogData);

        const logData = {
            ...userData,
            ...logEntry
        };

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(`/${endpoint}/append`, logData, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
