import { AlertType } from "@/types/interfaces";

export const alertTypes = [
    {
        pretty: "Info Light (light blue)",
        name: "info-light",
        icon: "info-circle"
    },
    {
        pretty: "Info (dark blue)",
        name: "info",
        icon: "info-circle"
    },
    {
        pretty: "Success (green)",
        name: "success",
        icon: "check-circle"
    },
    {
        pretty: "Warning (yellow)",
        name: "warning",
        icon: "exclamation-triangle"
    },
    {
        pretty: "Danger (red)",
        name: "danger",
        icon: "exclamation-circle"
    }
] as Array<AlertType>;
