import Vue from "vue";
import axios from "axios";
import linkifyHtml from "linkifyjs/html";
import store from "@/store";

axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";

export const getApiClient = () => {
    const apiClient = axios.create({
        baseURL: store.getters.apiConfig?.baseUrl,
        withCredentials: false,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    });

    const requestInterceptor = apiClient.interceptors.request.use(
        function(config) {
            const isAuthorized = store.getters.isAuthorized;
            if (isAuthorized || config.params?.isUserInfoEndpoint) {
                return config;
            } else {
                throw new axios.Cancel("Not Authorized to call API.");
            }
        },
        function(error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    requestInterceptor; //ts complaint.

    // In case we need to remove an interceptor later we can:
    // axios.interceptors.request.eject(requestInterceptor);

    // Add a response interceptor
    const responseInterceptor = apiClient.interceptors.response.use(
        function(response) {
            return response;
        },
        function(error) {
            const hasStatus = Boolean(error.response && error.response.status);

            if (hasStatus && 401 === error.response.status) {
                // clear token info, require client to re-authenticate
                store.dispatch("handleInvalidUser");
            }

            return Promise.reject(error);
        }
    );

    responseInterceptor; //ts complaint.

    // In case we need to remove an interceptor later we can:
    // axios.interceptors.request.eject(responseInterceptor);

    return apiClient;
};

export default {
    getTradeshowCompanies() {
        const token = store.state.userCredentials.idToken;
        return getApiClient().get(`/tradeshow`, {
            headers: {
                Authorization: `bearer ${token}`
            }
        });
    },
    getSession(sessionId: string) {
        const token = store.state.userCredentials.idToken;
        return getApiClient().get(`/session/${sessionId}`, {
            headers: {
                Authorization: `bearer ${token}`
            }
        });
    },
    getVideoComments(sessionId: string, after = "") {
        // 'after' is an iso string representing a point of time of earliest comments to get
        const token = store.state.userCredentials.idToken;
        return getApiClient().get(`/comments/${sessionId}?after=${after}`, {
            headers: {
                Authorization: `bearer ${token}`
            }
        });
    },
    getFeaturedVideo() {
        const token = store.state.userCredentials.idToken;
        return getApiClient().get("/featured-video", {
            headers: {
                Authorization: `bearer ${token}`
            }
        });
    },
    postVideoComment(sessionId: string, text: string) {
        const token = store.state.userCredentials.idToken;

        text = linkifyHtml(text, {
            attributes: {
                rel: "noopener noreferrer"
            }
        });

        text = Vue.prototype.MgSanitize(text);

        return getApiClient().post(
            `/comments/${sessionId}`,
            { text },
            {
                headers: {
                    Authorization: `bearer ${token}`
                }
            }
        );
    },
    createUrl(url: string, starts: number, expires: number) {
        const token = store.state.userCredentials.idToken;
        return getApiClient().post(
            "/video/url",
            {
                url,
                starts,
                expires
            },
            {
                headers: {
                    Authorization: `bearer ${token}`
                }
            }
        );
    },
    createCookies(url: string, starts: number, expires: number) {
        const token = store.state.userCredentials.idToken;
        return getApiClient().post(
            "/video/cookies",
            {
                url,
                starts,
                expires
            },
            {
                headers: {
                    Authorization: `bearer ${token}`
                }
            }
        );
    },
    getConversationParticipantData(attendees: Array<string>) {
        const token = store.state.userCredentials.idToken;

        return getApiClient().post(
            "/conversation-attendees",
            {
                attendees
            },
            {
                headers: {
                    Authorization: `bearer ${token}`
                }
            }
        );
    },
    getConversationFromId(otherId: string) {
        const token = store.state.userCredentials.idToken;
        const config = {
            headers: {
                Authorization: `bearer ${token}`
            }
        };

        return getApiClient().get(`/conversation-with-user/${otherId}`, config);
    }
};
