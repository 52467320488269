import { Module, VuexModule, Action, config } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import store from "../index";

// This module is only for functionality related to the following endpoint:
const endpoint = "helpdeskchat";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

@Module({
    dynamic: true,
    store: store,
    name: "HelpdeskChatModule",
    namespaced: true
})
export default class HelpdeskChatModule extends VuexModule {
    @Action({})
    startChat(payload: string) {
        const token = this.context.rootGetters.idToken;
        return new Promise((resolve, reject) => {
            getApiClient()
                .post(`/${endpoint}`, payload, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}
