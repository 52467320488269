<template>
    <svg
        width="68px"
        height="68px"
        viewBox="0 0 68 68"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- https://dd401jc05x2yk.cloudfront.net/css/ico-test-chat-down-arrow.svg -->
        <title>Close chat</title>
        <desc>Created with Sketch.</desc>
        <defs>
            <circle
                :id="`${_uid}-svg-chat-hide-path`"
                cx="27"
                cy="27"
                r="27"
            ></circle>
            <filter
                x="-21.3%"
                y="-17.6%"
                width="142.6%"
                height="142.6%"
                filterUnits="objectBoundingBox"
                :id="`${_uid}-svg-chat-hide-filter`"
            >
                <feOffset
                    dx="0"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="3.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(7.000000, 5.000000)">
                <g>
                    <g>
                        <use
                            fill="black"
                            fill-opacity="1"
                            :filter="`url(#${_uid}-svg-chat-hide-filter)`"
                            :xlink:href="`#${_uid}-svg-chat-hide-path`"
                        ></use>
                        <use
                            fill="currentColor"
                            fill-rule="evenodd"
                            :xlink:href="`#${_uid}-svg-chat-hide-path`"
                        ></use>
                    </g>
                </g>
                <polygon
                    fill="#FFFFFF"
                    points="17.8 21 27 30.2 36.2 21 39 23.8 27 35.8 15 23.8"
                ></polygon>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-chat-hide"
};
</script>
