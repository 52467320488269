<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        focusable="false"
        width="48"
        height="48"
        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 50.999 56.999"
    >
        <title>Feedburner</title>
        <path
            d="M34.52,21.922c-0.305-0.251-0.729-0.299-1.082-0.122c-0.353,0.176-0.569,0.544-0.552,0.938
			c0.011,0.254,0.017,0.509,0.017,0.767c0,6.36-3.385,11.535-7.545,11.535s-7.545-5.185-7.545-11.558
			c0-0.403-0.243-0.768-0.615-0.923c-0.373-0.154-0.801-0.071-1.089,0.213c-2.579,2.556-3.999,5.952-3.999,9.564
			c0,7.463,6.135,13.535,13.676,13.535S39.46,39.799,39.46,32.336C39.46,28.3,37.659,24.504,34.52,21.922z M25.784,43.871
			c-6.438,0-11.676-5.175-11.676-11.535c0-2.262,0.655-4.425,1.876-6.277c0.85,6.248,4.729,10.981,9.373,10.981
			c4.894,0,8.94-5.25,9.483-11.986c1.684,2.041,2.62,4.6,2.62,7.282C37.46,38.696,32.222,43.871,25.784,43.871z"
        />
        <path
            d="M43.871,33.623c0.003-0.273,0.005-0.56,0.005-0.859c0-11.008-5.24-13.98-11.307-17.422l-0.451-0.256
			c-0.322-0.185-0.721-0.173-1.034,0.027c-0.313,0.201-0.488,0.559-0.456,0.929c0.35,4.03-0.394,6.921-2.212,8.594
			c-1.429,1.313-3.276,1.619-4.514,1.656c1.289-2.956,3.784-9.949,0.87-14.441c-3.149-4.856-2.145-10.612-2.134-10.67
			c0.076-0.418-0.119-0.838-0.487-1.049c-0.368-0.21-0.828-0.167-1.15,0.11c-0.173,0.149-4.31,3.762-8.898,11.649
			c-3.83,6.583-3.893,17.135-3.756,21.196C3.601,35.023,1,37.674,1,40.598c0,5.833,10.762,10.401,24.5,10.401S50,46.431,50,40.598
			C50,37.958,47.83,35.496,43.871,33.623z M25.5,48.999c-13.459,0-22.5-4.344-22.5-8.401c0-2.137,2.452-4.288,6.728-5.903
			c0.405-0.153,0.665-0.55,0.646-0.982c-0.108-2.298-0.448-14.101,3.458-20.815c2.583-4.441,5.04-7.494,6.637-9.267
			c0.015,2.408,0.479,5.996,2.626,9.308c3.059,4.717-1.567,13.717-1.614,13.807c-0.147,0.282-0.151,0.618-0.011,0.904
			c0.141,0.285,0.409,0.487,0.722,0.543c0.18,0.033,4.469,0.756,7.562-2.069c1.995-1.823,2.986-4.644,2.958-8.399
			c5.383,3.092,9.165,5.828,9.165,15.04c0,0.536-0.006,1.027-0.016,1.477c-0.008,0.405,0.228,0.775,0.599,0.938
			C45.98,36.721,48,38.696,48,40.598C48,44.655,38.959,48.999,25.5,48.999z"
        />
    </svg>
</template>
<script>
export default {
    name: "svg-feedburner"
};
</script>
