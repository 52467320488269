<template>
    <div class="parallax">
        <div
            id="bigbg"
            class="parallax-layer parallax-layer__1"
            data-speed="20"
            :style="{ transform: getTransformForLayer(20) }"
        >
            <div class="cells">
                <ul class="cell">
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/bigcell-2.png`
                            "
                        />
                    </li>
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/bigcell-3.png`
                            "
                        />
                    </li>
                    <li class="cell__3">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/cell-2.png`
                            "
                        />
                    </li>
                    <li class="cell__4">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/cell-3.png`
                            "
                        />
                    </li>
                    <li class="cell__5">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/cell-6.png`
                            "
                        />
                    </li>
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/cell-7.png`
                            "
                        />
                    </li>
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/smcell-1.png`
                            "
                        />
                    </li>
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/smcell-2.png`
                            "
                        />
                    </li>
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/cell-7.png`
                            "
                        />
                    </li>
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/bigcell-2.png`
                            "
                        />
                    </li>
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/cell-3.png`
                            "
                        />
                    </li>
                    <li class="cell__2">
                        <img
                            :src="
                                `${this.assetHost}/assets/dist/images/cell-1.png`
                            "
                        />
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="parallax-layer parallax-layer__2"
            data-speed="40"
            :style="{ transform: getTransformForLayer(40) }"
        ></div>
        <div
            class="parallax-layer parallax-layer__3"
            data-speed="100"
            :style="{ transform: getTransformForLayer(100) }"
        ></div>
        <div
            class="parallax-layer parallax-layer__4"
            data-speed="60"
            :style="{ transform: getTransformForLayer(60) }"
        ></div>
        <div
            class="parallax-layer parallax-layer__5"
            data-speed="15"
            :style="{ transform: getTransformForLayer(15) }"
        ></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mouseX: 0,
            mouseY: 0,
            lastUpdated: null
        };
    },
    mounted() {
        document.addEventListener("mousemove", this.updateMousePosition);
    },
    computed: {
        pageOptions() {
            return this.$store.getters.getPageOptions("gate");
        },
        assetHost() {
            return this.pageOptions.assetsHost;
        }
    },
    methods: {
        updateMousePosition(e) {
            const now = Date.now();
            if (!this.lastUpdated || now - this.lastUpdated >= 100) {
                this.mouseX = e.clientX;
                this.mouseY = e.clientY;
                this.lastUpdated = now;
            }
        },
        getTransformForLayer(speed) {
            return `translateX(${(this.mouseX * speed) /
                1000}px) translateY(${(this.mouseY * speed) / 1000}px)`;
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../styles/components/_fact-animated-bg.scss";
</style>
