/**
 * Using tradtional module. This code needs to load / run asap.
 */
import { getApiClient } from "@/services/api";
const endpoint = "server-time";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    fetchServerTime({
        rootGetters
    }: {
        rootGetters: Record<string, string>;
    }): Promise<Record<string, string>> {
        return new Promise((resolve, reject) => {
            const token = rootGetters.idToken;

            return getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
