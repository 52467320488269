const options = {
    root: null,
    rootMargin: "0px 0px 100px 0px",
    threshold: 0
};
const oberver = new IntersectionObserver((entries, oberver) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const lazyTarget = entry.target;
            if (lazyTarget instanceof HTMLImageElement) {
                // Skip carousel items.
                // swiper has its own lazy load functionality.
                // make sure you add lazy load options to your swiper carousel.
                // https://swiperjs.com/swiper-api#lazy-loading
                // for reference see FeaturedExhibitorSwimlane.vue
                if (!lazyTarget.closest(".swiper-wrapper")) {
                    // first
                    lazyTarget.srcset = lazyTarget.dataset.srcset || "";
                    // then
                    lazyTarget.src = lazyTarget.dataset.src || "";
                    // finally
                    oberver.unobserve(entry.target);
                }
            }
        } else {
            return;
        }
    });
}, options);

export default {
    bind(el: HTMLElement) {
        oberver.observe(el);
    }
};
