<template>
    <a v-if="MgIsUrl(path, true)" :href="path">
        <slot></slot>
    </a>
    <router-link v-else :to="path">
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    props: ["path"]
};
</script>
