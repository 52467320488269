/**
 * NOTE don't run `router.push` (and potentially other router functionality) in this file.
 * Doing so causes dynamic vuex modules to not work in some cases (like top level / non-children components).
 */

import Vue from "vue";
import Vuex from "vuex";
import auth from "../services/Auth";
import screenfull from "screenfull";
import { PageOptionObject, AuthController } from "@/types/interfaces";
import { AxiosError } from "axios";

/**
 * Modules
 * please create a module for every API endpoint introduced.
 * modules are in ./vuex-modules/
 */
import serverTimeVuexModule from "@/store/vuex-modules/server-time";
import logVuexModule from "@/store/vuex-modules/log";
import settingsVuexModule from "@/store/vuex-modules/settings";
import userinfoVuexModule from "@/store/vuex-modules/userinfo";
import notificationsVuexModule from "@/store/vuex-modules/notifications";
import talkVuexModule from "@/store/vuex-modules/talk";
import optionsVuexModule from "@/store/vuex-modules/options";

Vue.use(Vuex);

const defaultAttendeeFieldNames = {
    firstName: "First Name",
    lastName: "Last Name",
    companyName: "Organization",
    country: "Country",
    state: "State",
    city: "City",
    title: "Title",
    specialInterests: "Special Interest",
    secondaryInterests: "Other Interest",
    system: "System",
    school: "School",
    graduationYear: "Graduation Year",
    designation: "Designation"
};

export default new Vuex.Store({
    modules: {
        serverTimeVuexModule,
        settingsVuexModule,
        notificationsVuexModule,
        userinfoVuexModule,
        talkVuexModule,
        logVuexModule,
        optionsVuexModule
    },
    state: {
        loginError: null,
        authWantsRoute: "",
        isFullScreen: false,
        enableHeader: true,
        enableFooter: true,
        themeConfigLoaded: false,
        themeConfig: {
            isSiteActive: {
                development: true,
                production: true,
                superUserAccessOnly: false
            },
            useMeetingTimezone: false,
            meetingType: "",
            sessionOptions: {},
            ads: "",
            favicon: "",
            logo: "",
            logoGate: "",
            slogan: "",
            logoMeetingView: "",
            footerLogo: "",
            hostLogoGate: "",
            mobileLogo: "",
            bgImage: "",
            navImage: "",
            decorImage: "",
            gateLogo: "",
            logoDescription: "",
            splashImage: "",
            conferenceName: "",
            displaySlogan: "",
            conferenceYear: "",
            conferenceId: "",
            layoutOptions: "",
            layoutImages: "",
            fontConfig: "",
            homeLabel: "",
            sessionLabel: "",
            attendeeLabels: "",
            tradeshowLabel: "",
            schedulePageLabel: "",
            messagePageLabel: "",
            socialMediaAccount: "",
            fontFamily: "",
            webfontSrc: "",
            cssVariables: "",
            customLayout: "",
            pageOptions: [],
            primaryInterests: [],
            staticCommunityExchange: [],
            staticExpectations: [],
            forceAuth: false,
            api: {
                development: {},
                production: {}
            },
            authConfig: {
                local: {},
                development: {},
                production: {}
            },
            awsConfig: {},
            helpdeskConnection: {
                region: "",
                api: "",
                instanceId: "",
                contactFlowId: ""
            },
            sponsorVideos: [],
            bannerAds: [],
            unauthorizedMessage: ""
        },
        user: {
            id: "",
            username: "",
            name: "",
            companyName: "",
            email: ""
        },
        userCredentials: {
            idToken: "",
            accessToken: "",
            refreshToken: "",
            expires: 0,
            isRefreshing: false
        },
        userIsAuthorized: false, // set to true if we can get the userinfo data after logging in,
        isLoadingUser: false, // activate spinner on gate page
        exhibitorSearchResults: []
    },
    getters: {
        badgeSettings: (state) => {
            return (
                state.themeConfig.badgeSettings || [
                    {
                        tag: 1,
                        bgColor: "#000",
                        textColor: "#fff",
                        fontSize: ".75em",
                        fontWeight: "700"
                    },
                    {
                        tag: 2,
                        bgColor: "#000",
                        textColor: "#fff",
                        fontSize: ".75em",
                        fontWeight: "700"
                    },
                    {
                        tag: 3,
                        bgColor: "#000",
                        textColor: "#fff",
                        fontSize: ".75em",
                        fontWeight: "700"
                    }
                ]
            );
        },

        isProduction: () => {
            return Boolean("production" === process.env.NODE_ENV);
        },

        isHybrid: (state) => {
            return Boolean(
                state.themeConfig?.meetingType == "hybrid" ? true : false
            );
        },

        isVirtual: (state) => {
            return Boolean(
                state.themeConfig?.meetingType == "virtual" ? true : false
            );
        },

        isSiteActive: (state) => {
            let returnValue = true;
            const option = state.themeConfig?.isSiteActive || {};

            if (Object.keys(option).length) {
                const x = process.env.NODE_ENV as string;
                returnValue = option[x];
            }

            return Boolean(returnValue);
        },

        isPageOptionActiveInEnv:
            (state, getters) => (page: string, option: string) => {
                let returnValue = true;
                const options = getters.getPageOptions(page) || {};
                const optionToCheck = options[option];

                if (optionToCheck && Object.keys(optionToCheck).length) {
                    const x = process.env.NODE_ENV as string;
                    returnValue = optionToCheck[x];
                }

                return Boolean(returnValue);
            },

        ads: (state) => {
            return state.themeConfig.ads;
        },

        helpdeskInstanceRegion: (state) => {
            return state.themeConfig.helpdeskConnection.region;
        },

        helpdeskInstanceApi: (state) => {
            return state.themeConfig.helpdeskConnection.api;
        },

        helpdeskInstanceId: (state) => {
            return state.themeConfig.helpdeskConnection.instanceId;
        },

        helpdeskContactFlowId: (state) => {
            return state.themeConfig.helpdeskConnection.contactFlowId;
        },

        isChatEnabled: (state) => {
            const connectionData = state.themeConfig.helpdeskConnection;

            for (const [key, value] of Object.entries(connectionData)) {
                key; //ts complaint

                if (!value) {
                    return false;
                }
            }

            return true;
        },

        layoutImages: (state) => {
            return state.themeConfig.layoutImages;
        },

        hostLogoGate: (state) => {
            return state.themeConfig.hostLogoGate;
        },
        socialMediaAccount: (state) => {
            return state.themeConfig.socialMediaAccount;
        },

        layoutOptions: (state) => {
            return state.themeConfig.layoutOptions || {};
        },

        sponsorVideos: (state) => {
            return state.themeConfig.sponsorVideos;
        },

        homeLabel: (state) => {
            return state.themeConfig.homeLabel;
        },

        sessionLabel: (state) => {
            return state.themeConfig.sessionLabel;
        },

        tradeshowLabel: (state) => {
            return state.themeConfig.tradeshowLabel;
        },

        attendeeLabels: (state) => {
            return state.themeConfig.attendeeLabels;
        },

        messagePageLabel: (state) => {
            return state.themeConfig.messagePageLabel;
        },

        schedulePageLabel: (state) => {
            return state.themeConfig.schedulePageLabel;
        },

        fontConfig: (state) => {
            return state.themeConfig.fontConfig;
        },

        cssVariables: (state) => {
            return state.themeConfig.cssVariables;
        },
        getstaticCommunityExchange: (state) => {
            return state.themeConfig.staticCommunityExchange;
        },
        getstaticExpectations: (state) => {
            return state.themeConfig.staticExpectations;
        },
        getDefaultTwitterSettings: (state) => {
            return state.themeConfig.twitterDefaultSettings;
        },

        getPageOptions: (state) => (page: string) => {
            const result = state.themeConfig.pageOptions.find(
                (item: PageOptionObject) => page === item.page
            );
            return result;
        },
        getSpecialInterest: (state) => {
            return state.themeConfig.primaryInterests;
        },
        getPrimaryInterests: (state) => (interest: string) => {
            return state.themeConfig.primaryInterests.items.find(
                (item: { imageSrc: string; name: string }) =>
                    item.name === interest
            );
        },
        getAttendeeLabels: (state, getters, rootState, rootGetters) => {
            const config = rootGetters.getPageOptions("attendees");

            if (config && config.fieldLabels) {
                return {
                    ...defaultAttendeeFieldNames,
                    ...config.fieldLabels
                };
            } else {
                return defaultAttendeeFieldNames;
            }
        },
        slogan: (state) => {
            return state.themeConfig.slogan;
        },
        logo: (state) => {
            let returnValue = "";
            if (state.themeConfig.logo) {
                returnValue = `/logos/${state.themeConfig.logo}`;
            }
            return returnValue;
        },
        logoMeetingView: (state) => {
            let returnValue = "";
            if (state.themeConfig.logoMeetingView) {
                returnValue = `/logos/${state.themeConfig.logoMeetingView}`;
            }
            return returnValue;
        },
        getBackgroundImage: (state) => {
            return state.themeConfig.bgImage;
        },
        logoGate: (state) => {
            return state.themeConfig.logoGate;
        },
        navImage: (state) => {
            return state.themeConfig.navImage;
        },
        favicon: (state) => {
            return state.themeConfig.favicon;
        },
        footerLogo: (state) => {
            return state.themeConfig.footerLogo;
        },
        mobileLogo: (state) => {
            return state.themeConfig.mobileLogo;
        },
        decorImage: (state) => {
            return state.themeConfig.decorImage;
        },
        gateLogo: (state) => {
            return state.themeConfig.gateLogo;
        },
        logoDesc: (state) => {
            return state.themeConfig.logoDescription;
        },
        splashImage: (state) => {
            return state.themeConfig.splashImage;
        },
        conferenceName: (state) => {
            return state.themeConfig.conferenceName;
        },
        displaySlogan: (state) => {
            return state.themeConfig.displaySlogan;
        },
        conferenceYear: (state) => {
            return state.themeConfig.conferenceYear;
        },
        apiConfig: (state) => {
            const option = state.themeConfig?.api || {};
            const env = process.env.NODE_ENV as string;
            let returnValue = {};

            if (option[env]) {
                returnValue = option[env];
            }

            return returnValue;
        },
        authConfig: (state) => {
            const option = state.themeConfig?.authConfig || {};
            const env =
                window.location.hostname == "localhost" &&
                state.themeConfig.authConfig.local &&
                state.themeConfig.authConfig.local.loginURL
                    ? "local"
                    : (process.env.NODE_ENV as string);
            let returnValue = {};

            if (option[env]) {
                returnValue = option[env];
            }

            return returnValue;
        },
        authorizer: (state, getters) => {
            // console.log('authConfig', getters.authConfig)
            // console.log('state authConfig', state.themeConfig.authConfig)
            const customAuthorizer: string =
                getters.authConfig.customAuthorizer || "";
            // console.log(getters.authConfig)
            // console.log(customAuthorizer)
            const authObject: Record<string, AuthController> = auth;
            if (authObject[customAuthorizer]) {
                return authObject[customAuthorizer];
            } else {
                return authObject.oauth;
            }
        },
        awsConfig: (state) => state.themeConfig.awsConfig,
        user: (state) => state.user,
        isAuthenticated: (state) => Boolean(state.userCredentials.idToken),
        isAuthorized: (state) => state.userIsAuthorized,
        isSessionExpired: (state) => {
            const currentTimeSeconds = new Date().getTime() / 1000;
            return currentTimeSeconds >= state.userCredentials.expires;
        },
        exhibitorSearchResults: (state) => state.exhibitorSearchResults,

        idToken: (state) => state.userCredentials.idToken,
        webfontSrc: (state) => state.themeConfig.webfontSrc,
        bannerAds: (state) => state.themeConfig.bannerAds,
        firstLoginSettings: (state) =>
            state.themeConfig.firstLoginSettings
                ? state.themeConfig.firstLoginSettings
                : {
                      enabled: false,
                      path: "",
                      text: ""
                  },
        timezoneForDates: (state) =>
            state.themeConfig.useMeetingTimezone
                ? state.settingsVuexModule.meetingTimezone
                : new Intl.DateTimeFormat().resolvedOptions().timeZone
    },

    mutations: {
        setLoginError(state, payload: Error) {
            state.loginError = payload;
        },
        setAuthWantsRoute(state, payload: string) {
            state.authWantsRoute = payload;
        },
        setIsFullScreen(state) {
            if (screenfull.isEnabled) {
                state.isFullScreen = screenfull.isFullscreen;
            } else {
                state.isFullScreen = false;
            }
        },

        setUser(state, payload = {}) {
            if (payload.idToken) {
                state.userCredentials.idToken = payload.idToken;
                state.userCredentials.accessToken = payload.accessToken;
                state.userCredentials.refreshToken = payload.refreshToken;
                state.userCredentials.expires = payload.expires;
            } else {
                state.userCredentials.idToken = "";
                state.userCredentials.accessToken = "";
                state.userCredentials.refreshToken = "";
                state.userCredentials.expires = 0;
            }
            localStorage.setItem("token", JSON.stringify(payload));
        },
        setIsAuthorized(state, payload: boolean) {
            state.userIsAuthorized = payload;
        },
        setIsLoadingUser(state, payload: boolean) {
            state.isLoadingUser = payload;
        },
        setRefreshing(state, payload) {
            state.userCredentials.isRefreshing = payload;
            // console.log(`set refreshing to ${payload}`);
        },
        setThemeConfig(state, payload) {
            state.themeConfig = payload;
        },
        setThemeConfigLoaded(state, payload) {
            state.themeConfigLoaded = payload;
        },
        setEnableHeader(state, payload) {
            state.enableHeader = payload;
        },
        setEnableFooter(state, payload) {
            state.enableFooter = payload;
        },
        setCSSvariables(state) {
            if (
                state.themeConfigLoaded &&
                state.themeConfig &&
                state.themeConfig.cssVariables
            ) {
                if (!state.themeConfig.cssVariables) return;

                Object.entries(state.themeConfig.cssVariables).forEach(
                    ([key, value]) => {
                        const documentElement = document.documentElement;
                        const x = value as string;
                        if (0 === key.indexOf("--") && x) {
                            documentElement.style.setProperty(key, x);
                        }
                    }
                );
            }
        },
        exhibitorSearchResults(state, payload) {
            state.exhibitorSearchResults = payload;
        }
    },
    actions: {
        toggleFullScreen: ({ commit }) => {
            Vue.prototype.MgToggleFullScreen().finally(() => {
                commit("setIsFullScreen");
            });
        },
        enableWrapper: ({ commit }) => {
            commit("setEnableHeader", true);
            commit("setEnableFooter", true);
        },
        disableWrapper: ({ commit }) => {
            commit("setEnableHeader", false);
            commit("setEnableFooter", false);
        },
        handleInvalidUser: ({ commit }) => {
            // clear browser token info, require client to re-authenticate
            commit("setUser", {});
            localStorage.setItem("token", JSON.stringify({}));
            commit("setAuthWantsRoute", "/gate");
        },
        loginUser: async (
            { commit, state, dispatch, getters },
            authPayload
        ) => {
            if (!authPayload.idToken) {
                return Promise.reject("no id token");
            }
            if (!authPayload.expires) {
                return Promise.reject("no expiration on token");
            }
            commit("setUser", authPayload);

            try {
                commit("setIsLoadingUser", true);
                await dispatch("getUserinfo");
                commit("setIsAuthorized", true);
            } catch (error) {
                const err = error as AxiosError;
                // if the response is unauthorized, the request is failing at the authorizer
                // level so let's remove their user data so they can run through login/auth again.
                console.error(err);
                if (err.response && err.response.status === 401) {
                    console.error("Token has been rejected by the authorizer.");
                    dispatch("handleInvalidUser");
                }
            } finally {
                commit("setIsLoadingUser", false);
            }

            if (!getters.isAuthorized) {
                // abort any further login user actions
                return;
            }

            const user = getters.userInfo;
            dispatch("setupTalk");
            // establish Rollbar user
            Vue.prototype.$rollbar.configure({
                payload: {
                    person: {
                        id: user.id,
                        name: user.name
                    }
                }
            });
            const currentTimeSeconds = new Date().getTime() / 1000;
            // refresh 5 minutes before expiration
            let tokenRefreshTimer =
                state.userCredentials.expires - currentTimeSeconds - 300;
            // maximum refresh timer to not overflow the setTimeout. This is about 12 days.
            if (tokenRefreshTimer > 1073742) tokenRefreshTimer = 1073742;

            try {
                // TODO: Fix async code on setTimeout? I don't want to mess with this too much while it
                // still works. But should loop back to this soon.
                setTimeout(
                    async () =>
                        await dispatch("refreshToken").catch(() => {
                            dispatch("handleInvalidUser");
                        }),
                    tokenRefreshTimer * 1000
                );
                // console.log("done done");

                const selectedEntrance =
                    localStorage.getItem("selectedEntrance");
                if (selectedEntrance) {
                    commit("setAuthWantsRoute", "/entrance");
                } else {
                    const storedPath = localStorage.getItem("storedPath");
                    if (storedPath) {
                        localStorage.removeItem("storedPath");
                        commit("setAuthWantsRoute", storedPath);
                    }
                }
            } catch (err) {
                console.error(err);
                return Promise.reject("Failed token refresh");
            }
        },
        refreshToken: async ({ state, getters, dispatch, commit }) => {
            if (state.userCredentials.isRefreshing)
                return Promise.reject("Already refreshing");
            commit("setRefreshing", true);
            try {
                if (!state.userCredentials.refreshToken) {
                    return Promise.reject("No refresh token");
                }
                const authConfig = getters.authConfig;
                const refreshToken = state.userCredentials.refreshToken;
                const token = await getters.authorizer
                    .refreshToken(authConfig, refreshToken)
                    .catch((error: Error) => {
                        Vue.prototype.$rollbar.warning(
                            `Failed to refresh token ${state.userCredentials.refreshToken}: ${error.message}`
                        );
                        return Promise.reject("Failed to refresh token");
                    });
                await dispatch("loginUser", token);
            } catch (err) {
                return Promise.reject("failed refresh");
            } finally {
                commit("setRefreshing", false);
            }
        },
        logout: async ({ commit, dispatch, state, getters }) => {
            const token = state.userCredentials.idToken;
            const authConfig = getters.authConfig;

            // first
            try {
                const route = state.route;
                await dispatch("PresenceModule/deleteRoutePresence", route);
            } catch (error) {
                // n/a
            }

            // then
            commit("setUser", {});
            if (token && authConfig?.logoutURL) {
                window.location.href = authConfig.logoutURL;
            } else {
                commit("setAuthWantsRoute", "/gate");
            }
        },
        handleOneSignal: ({ state }) => {
            const settings = state.themeConfig.oneSignalSettings;
            const oneSignalId = settings && settings.id ? settings.id : "";
            const oneSignalHost =
                settings && settings.hostname ? settings.hostname : "";
            // TODO: we will just need to find a way to put this on config
            if (
                window.OneSignal &&
                oneSignalId != "" &&
                window.location.hostname === oneSignalHost
            ) {
                window.OneSignal.push(function () {
                    window.OneSignal.init({
                        appId: oneSignalId
                    });
                });
            }
        },
        handleAppSettings: ({ dispatch }) => {
            dispatch("getAndSetMeetingSettings");
            dispatch("handleOneSignal");
        }
    }
});
