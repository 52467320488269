


























































import Vue from "vue";
import NavBar from "@/components/header/navbar.vue";

export default Vue.extend({
    data() {
        return {
            isOpen: false,
            nav: "",
            bg: "",
            color: "",
            publicPath: process.env.BASE_URL,
            isReady: false
        };
    },
    components: {
        NavBar
    },
    computed: {
        /**
         * Computed
         */
        headerOptions(): boolean {
            return this.$store.getters.layoutOptions?.headerOptions || {};
        },

        fontTouse(): string {
            const options = this.$store.getters.getPageOptions("header") || {};
            return options.fontToUse;
        },

        siteLogo(): string {
            return this.$store.getters.logo;
        },

        mobileLogo(): string {
            return this.$store.getters.mobileLogo;
        },

        logoDesc(): string {
            return this.$store.getters.logoDesc;
        },

        decorImage(): string | boolean {
            if (this.$store.getters.decorImage) {
                return (
                    this.publicPath + "img/" + this.$store.getters.decorImage
                );
            }
            return false;
        },

        navImage(): string | boolean {
            if (this.$store.getters.navImage) {
                return (
                    this.publicPath +
                    "nav-images/" +
                    this.$store.getters.navImage
                );
            }
            return false;
        },

        cssVariables(): Record<string, string> {
            return this.$store.getters.cssVariables;
        }
    }
});
