import Vue from "vue";
import {
    Module,
    VuexModule,
    Mutation,
    Action,
    config
} from "vuex-module-decorators";
import { isBefore } from "date-fns";
import { getApiClient } from "@/services/api";
import store from "../index";

// This module is only for functionality related to the following endpoint:
const endpoint = `signedURL`;

// Set rawError to true by default on all @Action decorators
config.rawError = true;

@Module({
    dynamic: true,
    store: store,
    name: "SignedUrlModule",
    namespaced: true
})
export default class SignedUrlModule extends VuexModule {
    routerHasRequestedSignedUrl = false;
    loadingSignedUrl = false;
    signedUrl = {
        url: "",
        expires: ""
    };
    signedUrlTimeout = -1;

    get signedUrlAddress() {
        return this.signedUrl.url;
    }

    get getSignedResourceUrl() {
        /**
         * Get a signed url for an protected resource (e.g. an s3 image)
         * @param {src} string
         */
        return (src = "") => {
            let returnValue = "";
            const signedUrlAddress = this.signedUrlAddress;

            if (!src) return returnValue;

            try {
                if (!signedUrlAddress) throw new Error("no signed url");
                const url = new URL(src);
                returnValue = signedUrlAddress.replace("/*", url.pathname);
            } catch (error) {
                // this is likely a relative url, just use this.
                returnValue = src;
            }

            return returnValue;
        };
    }

    @Mutation
    setSignedUrlTimeout(payload: -1) {
        this.signedUrlTimeout = payload;
    }

    @Mutation
    setRouterHasRequestedSignedUrl(payload: true) {
        this.routerHasRequestedSignedUrl = payload;
    }

    @Mutation
    setLoadingSignedUrl(payload: false) {
        this.loadingSignedUrl = payload;
    }

    @Mutation
    setSignedUrl(payload: { url: ""; expires: "" }) {
        this.signedUrl = payload;
    }

    @Action({ commit: "setSignedUrl" })
    getAndSetSignedUrl() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            // first
            window.clearTimeout(this.signedUrlTimeout);

            // then
            this.context.commit("setLoadingSignedUrl", true);
            getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    const data = response.data;
                    const timeout = window.setTimeout(() => {
                        /**
                         * There's an issue where _sometimes_ the signed URL does not work right away.
                         * so we'll wait x seconds. not a good solution.
                         * this is temp code until we find out why this is.
                         */
                        this.context.commit("setLoadingSignedUrl", false);
                    }, 1000);

                    this.context.commit("setSignedUrlTimeout", timeout);

                    window.localStorage.setItem(
                        "signed-url",
                        JSON.stringify(data)
                    );

                    return resolve(data);
                })
                .catch((err) => {
                    this.context.commit("setLoadingSignedUrl", false);
                    return reject(err);
                });
        });
    }

    @Action({})
    IsSignedUrlExpired() {
        const rightNow = Vue.prototype.MgRightNow();
        const storageSignedUrl = window.localStorage.getItem("signed-url");
        let storageSignedUrlObject;
        let storageSignedUrlExpiresDate;
        let storageSignedUrlHasExpired = true;

        if (storageSignedUrl) {
            try {
                storageSignedUrlObject = JSON.parse(storageSignedUrl);
                if (
                    storageSignedUrlObject.expires &&
                    Vue.prototype.MgIsValidISOString(
                        storageSignedUrlObject.expires
                    )
                ) {
                    storageSignedUrlExpiresDate = new Date(
                        storageSignedUrlObject.expires
                    );
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (storageSignedUrlExpiresDate instanceof Date) {
            storageSignedUrlHasExpired = isBefore(
                storageSignedUrlExpiresDate,
                rightNow
            );
        }

        // only `setSignedUrl` if `storageSignedUrlObject` has valid info
        if (!storageSignedUrlHasExpired && storageSignedUrlObject) {
            this.context.commit("setSignedUrl", storageSignedUrlObject);
        }

        return storageSignedUrlHasExpired;
    }
}
