















































































import store from "@/store";
import Vue from "vue";
import svgTwitter from "@/components/svg/svg-twitter.vue";
import svgLinkedin from "@/components/svg/svg-linkedin.vue";
import svgInstagram from "@/components/svg/svg-instagram.vue";
import svgFeedBurner from "@/components/svg/svg-feedburner.vue";
import svgYoutube from "@/components/svg/svg-youtube.vue";
import svgFacebook from "@/components/svg/svg-facebook.vue";
import svgPinterest from "@/components/svg/svg-pinterest.vue";

import { ConfigPageOptionsFooter } from "@/types/interfaces";

interface SocialMediaAccount {
    facebook?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
    youtube?: string;
    feedburner?: string;
    pinterest?: string;
}

interface SocialData {
    network: string;
    prettyName: string;
    component: string;
    url: string;
}

export default Vue.extend({
    data() {
        return {
            publicPath: process.env.BASE_URL,

            /**
             * Intentionally a hard coded string.
             *
             * Syntax:
             * month-day-year / (number to bump / reset if date is changed)
             * example: `01-01-21 / 2`
             *
             */
            lastUpdateDate: `07-19-21 / 3`
        };
    },
    components: {
        "svg-twitter": svgTwitter,
        "svg-linkedin": svgLinkedin,
        "svg-instagram": svgInstagram,
        "svg-feedburner": svgFeedBurner,
        "svg-youtube": svgYoutube,
        "svg-facebook": svgFacebook,
        "svg-pinterest": svgPinterest
    },
    // computed
    computed: {
        isProduction(): boolean {
            return store.getters.isProduction;
        },

        footerOptions(): ConfigPageOptionsFooter {
            return store.getters.getPageOptions("footer");
        },

        mainFooterContent(): string {
            return this.footerOptions?.content?.mainContent
                ? this.footerOptions?.content?.mainContent
                : "";
        },

        socialMediaAccount(): SocialMediaAccount {
            return store.getters.socialMediaAccount;
        },

        socialData(): Array<SocialData> {
            return Object.keys(this.socialMediaAccount)
                .filter((key) => {
                    const realKey = key as keyof SocialMediaAccount;
                    return (
                        this.socialMediaAccount[realKey] &&
                        this.socialMediaAccount[realKey] != ""
                    );
                })
                .map((key) => {
                    const realKey = key as keyof SocialMediaAccount;

                    let prettyName =
                        realKey.charAt(0).toUpperCase() + realKey.slice(1);

                    if (realKey === "feedburner") {
                        prettyName = "Feed Burner";
                    } else if (realKey === "youtube") {
                        prettyName = "YouTube";
                    } else if (realKey === "linkedin") {
                        prettyName = "LinkedIn";
                    }

                    return {
                        network: realKey,
                        prettyName,
                        component: `svg-${realKey}`,
                        url: this.socialMediaAccount[realKey] as string
                    };
                });
        },

        footerBG(): string {
            const footerImage = store.getters.layoutImages.footer || "";
            const path = `${this.publicPath}footer-images/`;
            let returnValue = "";

            if (footerImage) {
                returnValue = `background-image:url(${path}${footerImage})`;
            }

            return returnValue;
        },

        footerLogo(): string {
            return this.publicPath + "logos/" + store.getters.footerLogo;
        },

        isSuperUser(): boolean {
            return store.getters.isSuperUser;
        }
    },

    // lifecycle

    // Methods
    methods: {
        logout() {
            store.dispatch("logout");
        }
    }
});
