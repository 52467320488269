import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { PendingSessionEvaluation } from "@/types/interfaces";
import Store from "../index";

// This module uses multiple endpoints that are related.
const evalEndpoint = "mySessionEvals";

@Module({
    dynamic: true,
    store: Store,
    name: "SessionEvalModule",
    namespaced: true
})
export default class SessionEvalModule extends VuexModule {
    sessionId = "";
    sessionName = "";
    myPendingEvals: Array<PendingSessionEvaluation> = [];

    get isActive() {
        return this.sessionId !== "";
    }

    get activeSessionData() {
        return this.context.rootState.getSessionData.singleSession;
    }

    @Mutation
    public setSessionId(data: string) {
        this.sessionId = data;
    }

    @Mutation
    public setSessionName(data: string) {
        this.sessionName = data;
    }

    @Mutation
    public setMyPendingEvals(data: Array<PendingSessionEvaluation>) {
        this.myPendingEvals = data;
    }

    @Action({})
    async activateSessionEval(session: PendingSessionEvaluation) {
        if (!session.hasCompleted) {
            this.context.commit("setSessionId", session.sessionId);
            this.context.commit("setSessionName", session.title);
        } else {
            this.context.dispatch("deactivateSessionEval");
        }
    }

    @Action({})
    deactivateSessionEval() {
        this.context.commit("setSessionId", "");
        this.context.commit("setSessionName", "");
    }

    @Action({ commit: "setMyPendingEvals" })
    async getMyPendingEvals() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${evalEndpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    let items: Array<PendingSessionEvaluation> = [];

                    if (response && Array.isArray(response.data)) {
                        response.data.forEach((item) => {
                            items.push(item);
                        });
                    } else {
                        items = [];
                    }

                    return resolve(items);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
