





































import Vue from "vue";
// import { defineComponent } from "vue";
import store from "@/store";
import { isAfter, isBefore } from "date-fns";
import {
    SiteOptions,
    AlertMessage,
    AlertMessageEditable
} from "@/types/interfaces";
import { alertTypes } from "@/assets/dictionaries/alertTypes";
import { Route } from "vue-router";

export default Vue.extend({
    name: "HandleAppAlerts",
    data() {
        return {
            refetchInterval: -1,
            updatesAreAvailable: false,
            dismissedAlerts: [] as Array<string>
        };
    },
    computed: {
        isUpdateAvailable(): boolean {
            return document.getElementById("updatesAreAvailable")
                ? true
                : false;
        },
        route(): Route {
            return store.state.route || {};
        },
        displayNotification(): boolean {
            // TODO
            // speakeasies are currently buggy with direct browse
            // so we dont want to encourage page refreshes in speakeasies
            const disableOnRoutes = ["MeetingView"];
            const routeIsAllowed = !disableOnRoutes.includes(
                this.route.name || ""
            );

            return routeIsAllowed;
        },
        options(): SiteOptions {
            return store.state.optionsVuexModule.options;
        },
        alerts(): Array<AlertMessage | AlertMessageEditable> {
            return Array.isArray(this.options.alertMessages)
                ? this.options.alertMessages
                : [];
        },
        alertsToShow(): Array<AlertMessage | AlertMessageEditable> {
            return this.alerts.filter((item) => {
                const isNotDismissed = !this.dismissedAlerts.includes(item.id);
                const now = new Date();
                const start = item.startDate ? new Date(item.startDate) : false;
                const end = item.endDate ? new Date(item.endDate) : false;
                // If we don't have a start date, never show.
                const isAfterStart = start ? isAfter(now, start) : false;
                // If we don't have an end date, don't bother with this condition.
                const isBeforeEnd = end ? isBefore(now, end) : true;

                return isNotDismissed && isAfterStart && isBeforeEnd;
            });
        },
        sortedAlerts(): Array<AlertMessage | AlertMessageEditable> {
            /**
             * Alerts are sorted like this:
             *   - Alerts that can't be closed should always appear first,
             *   - Within each set (unclosable && closable) sort descending by start date
             */
            const newArray = [...this.alertsToShow];

            // The 1900 dates should never be used since by this point we've filtered out items with no start dates.
            newArray.sort((a, b) => {
                const aD = a.startDate
                    ? new Date(a.startDate)
                    : new Date("1900-01-01");
                const bD = b.startDate
                    ? new Date(b.startDate)
                    : new Date("1900-01-01");

                return isAfter(aD, bD) ? -1 : isBefore(aD, bD) ? 1 : 0;
            });

            newArray.sort((a, b) =>
                a.canClose && !b.canClose
                    ? 1
                    : !a.canClose && b.canClose
                        ? -1
                        : 0
            );

            return newArray;
        }
    },
    created() {
        this.getDismissedAlerts();
        this.checkForUpdates();
        this.handleCheckForUpdates();
    },
    beforeDestroy() {
        window.clearInterval(this.refetchInterval);
    },
    methods: {
        handleCheckForUpdates(): void {
            const ONE_SECOND = 1000;
            const INTERVAL = ONE_SECOND * 60;
            window.clearInterval(this.refetchInterval);
            this.refetchInterval = window.setInterval(
                this.checkForUpdates,
                INTERVAL
            );
        },
        checkForUpdates(): void {
            store.dispatch("getOptions", ["alertMessages"]);
        },
        getDismissedAlerts(): void {
            const alertData = localStorage.getItem("dismissedAlerts");

            this.dismissedAlerts = alertData ? JSON.parse(alertData) : [];
        },
        handleDismiss(id: string) {
            this.dismissedAlerts.push(id);
            localStorage.setItem(
                "dismissedAlerts",
                JSON.stringify(this.dismissedAlerts)
            );
        },
        getAlertIcon(alert: AlertMessage | AlertMessageEditable): string {
            if (alert.icon) {
                return alert.icon;
            }

            const foundType = alertTypes.find(
                (aType) => aType.name === alert.alertType
            );

            return foundType ? foundType.icon : "info-circle";
        }
    }
});
