import {
    Module,
    VuexModule,
    Mutation,
    Action,
    config
} from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import store from "../index";
import { Route } from "vue-router";

// This module is only for functionality related to the following endpoint:
const endpoint = "presence";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

@Module({
    dynamic: true,
    store: store,
    name: "PresenceModule",
    namespaced: true
})
export default class PresenceModule extends VuexModule {
    routePresence: Array<Record<string, string>> = [];

    @Mutation
    public setRoutePresence(data: Array<Record<string, string>>) {
        this.routePresence = data;
    }

    @Action({})
    addRoutePresence(id: string) {
        const token = this.context.rootGetters.idToken;
        return new Promise((resolve, reject) => {
            getApiClient()
                .post(`/${endpoint}/${id}`, null, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    @Action({})
    getRoutePresence(id: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            const ISOtime = new Date().toISOString();
            // calling with ISOtime to prevent caching.
            // clicking back button is an example case when browser will use cached request.
            getApiClient()
                .get(`/${endpoint}/${id}?time=${ISOtime}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    @Action({})
    public deleteRoutePresence(route: Route) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            const routeName = route.name;
            const routeId = route.params.id;

            if (!routeId || !routeName) {
                return reject("not enough info in deleteRoutePresence");
            }

            const routeIdentifier = `${routeName}_${routeId}`;
            getApiClient()
                .delete(`/${endpoint}/${routeIdentifier}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then(() => {
                    window.localStorage.removeItem("last-known-presence");
                    return resolve(routeIdentifier);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
