// Global Vue methods -
// not using mixins for performance reasons and because these are pure functions.

import Vue from "vue";
import axios from "axios";
import orderBy from "lodash/orderBy";
import { format, parseISO, parse, isDate, isToday, isValid } from "date-fns";
import screenfull from "screenfull";
import sanitizeHtml from "sanitize-html";
import { v4 as uuidv4 } from "uuid";

/**
 * Get a unique id
 */
Vue.prototype.MgGetUUID = function () {
    return uuidv4();
};

/**
 * Get alphabet as array
 */
Vue.prototype.MgGetAlphabet = function () {
    const a = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return a.split("");
};

/**
 * Strip out unwanted stags html an html string
 * * @param {string} html string
 */
Vue.prototype.MgSanitize = function (string = "", stripAll = false) {
    const options = {
        allowedTags: sanitizeHtml.defaults.allowedTags,
        allowedAttributes: {
            a: ["href", "rel", "target"],
            div: ["class"]
        },
        allowedSchemesAppliedToAttributes: ["class"]
    };

    if (stripAll) {
        options.allowedTags = [];
    }

    return sanitizeHtml(string, options);
};

/**
 * Filter out object properties with `undefined` as value
 * Returns a new object - prevserves original object
 * @param {object} object
 */
Vue.prototype.MgDefinedProps = function (object = {}) {
    return Object.fromEntries(
        Object.entries(object).filter(([k, v]) => {
            k; //settle unused var warning.
            return v !== undefined;
        })
    );
};

/**
 * Format an elastic search result
 * @param {object} searchResult
 */
Vue.prototype.MgFormatElasticSearchResult = function (searchResult = {}) {
    const returnValue = {
        attendeeId: searchResult?.attendeeId,
        firstName: searchResult?.firstName,
        lastName: searchResult?.lastName,
        companyName: searchResult?.companyName,
        email: searchResult?.email,
        title: searchResult?.title,
        images: searchResult?.images,
        country: searchResult?.country,
        city: searchResult?.city,
        state: searchResult?.state,
        system: searchResult?.system,
        MI: searchResult?.MI,
        prefix: searchResult?.prefix,
        suffix: searchResult?.suffix,
        school: searchResult?.school,
        graduationYear: searchResult.graduationYear,
        excludeFromMessaging: searchResult.excludeFromMessaging,
        excludeFromAppointments: searchResult.excludeFromAppointments,
        isExhibiting: searchResult.isExhibiting,
        attendeeBanner: searchResult.attendeeBanner
    };
    return returnValue;
};

/**
 * Sort an array of objects by property name
 * @param {array} array
 * @param {string} property
 * @param {string} sortOrder
 */
Vue.prototype.MgSortByProperty = function (
    array = null,
    property = null,
    sortOrder = "asc"
) {
    if (!array || !property) {
        return [];
    }

    if (!property) {
        return array;
    }

    return orderBy(array, [(item) => item[property]], [sortOrder]);
};

/**
 * Pass in an ISO string and return if it is valid or not
 * @param {string} isoString
 */
Vue.prototype.MgIsValidISOString = function (isoString = "") {
    const isoToDateObj = parseISO(
        isoString,
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
        new Date()
    );
    return isValid(isoToDateObj);
};

/**
 * Pass in an ISO string and return formatted string
 * @param {string} isoString
 */
Vue.prototype.MgFormatISODateTime = function (
    isoString = "",
    formatType = "",
    timeZone = undefined
) {
    if (!isoString) return "";
    const isoToDateObj = parseISO(isoString);

    if (!isValid(isoToDateObj)) {
        return "";
    }

    const baseOptions = {
        timeZone: timeZone
    };

    const withHourMinute = {
        hour: "numeric",
        minute: "numeric"
    };

    const monthDay = {
        month: "long",
        day: "numeric"
    };

    const monthDayYear = {
        ...monthDay,
        year: "numeric"
    };

    const date = new Date(isoToDateObj);

    if (!formatType || formatType == "date-time") {
        const options = {
            ...baseOptions,
            ...withHourMinute,
            ...monthDay
        };

        return new Intl.DateTimeFormat(undefined, options).format(date);
    } else if (formatType == "time") {
        const options = {
            ...baseOptions,
            ...withHourMinute
        };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    } else if (formatType == "day-month-year") {
        const options = {
            ...baseOptions,
            ...monthDayYear
        };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    } else if (formatType == "day-month") {
        const options = {
            ...baseOptions,
            ...monthDayYear,
            weekday: "short"
        };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    } else if (formatType == "fullday-month") {
        const options = {
            ...baseOptions,
            ...monthDayYear,
            weekday: "long"
        };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    } else if (formatType == "yyyy-mm-dd") {
        // This format is used for data sorting NOT for display.

        // This seems odd, but we want to pass through Intl.DateTimeFormat so we get the output in the correct timezone.
        return new Intl.DateTimeFormat("en-GB", {
            ...baseOptions,
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })
            .format(date)
            .split("/")
            .reverse()
            .join("-");
    } else {
        const options = {
            ...baseOptions,
            ...monthDayYear
        };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    }
};

/**
 * Get the current time - return Date object
 */
Vue.prototype.MgRightNow = function () {
    const now = window.MgServerTime ? window.MgServerTime : new Date();
    return now;
};

/**
 * Is a date string / date today?
 * @param {string | date} value
 */
Vue.prototype.MgIsToday = function (value = "") {
    if (!value) return value;

    let date;

    if (isDate(value)) {
        date = value;
    } else if ("string" === typeof value) {
        date = parse(value, "yyyy-MM-dd", Vue.prototype.MgRightNow());
    } else {
        return false;
    }

    return isToday(date);
};

/**
 * Toggle fullscreen mode
 */
Vue.prototype.MgToggleFullScreen = function () {
    return new Promise((resolve, reject) => {
        if (screenfull.isEnabled) {
            return screenfull
                .toggle()
                .then(() => {
                    return resolve();
                })
                .catch((error) => {
                    return reject(error);
                });
        } else {
            return reject(new Error("Full screen not allowed"));
        }
    });
};

/**
 * Pass in a youtube/vimeo url like "https://vimeo.com/244471846"
 * Uses oEmbed API: https://oembed.com/
 *
 * Note: Youtube has a dedicated endpoint and not included here because:
 * client side calls to the youtube oembed api are currently not allowed
 *
 * @param {string} url
 */
Vue.prototype.MgGetVideoEmbed = function (url = "") {
    return new Promise((resolve, reject) => {
        // TODO we can support facebook.com as well once this is done: https://matrix-group.atlassian.net/browse/VMP-657
        const validServices = ["vimeo.com"];

        let isUrlValid = false;
        let rejectMessage = "";
        let requestUrl = "";

        for (let index = 0; index < validServices.length; index++) {
            const service = validServices[index];
            if (url.includes(service)) {
                isUrlValid = true;
                break;
            }
        }

        if (!url) {
            rejectMessage = "no url provided";
        } else if (!isUrlValid) {
            rejectMessage = "video url is not from a valid service";
        }

        if (rejectMessage) {
            return reject(rejectMessage);
        }

        if (url.includes("vimeo.com")) {
            requestUrl = `https://vimeo.com/api/oembed.json?url=${url}`;
        }

        axios
            .get(requestUrl)
            .then((response) => {
                return resolve(response.data.html);
            })
            .catch((error) => {
                return reject(error);
            });
    });
};

/**
 * Is a given string a URL?
 * @param {string} url
 * @param {boolean} skipwarning
 */
Vue.prototype.MgIsUrl = function (url = "", skipwarning = false) {
    let returnValue = false;

    try {
        new URL(url);
        returnValue = true;
    } catch (error) {
        error;
        if (url && !skipwarning) {
            console.warn(`${url} is not a url`);
        }
    }

    return returnValue;
};

/**
 * Replace a strings spaces and symbols with a hyphen
 * @param {string} url
 */
Vue.prototype.MgSimpleSlugify = function (string) {
    return string.replace(/[^a-z0-9]/gi, "-").toLowerCase();
};

export default Vue;
