import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import Talk from "talkjs";

// This module is only for functionality related to the following endpoint:
const endpoint = "talk-signature";

interface TalkSessionInput {
    appId: string;
    me: Talk.User;
    signature?: string;
}

@Module
export default class TalkModule extends VuexModule {
    talkUser?: Talk.User;
    talkSession?: Talk.Session;
    talkUnreadMessages = 0;

    get talkActiveSettings() {
        const dev = {
            appId: "tBHhDGVS",
            useSignature: true
        };

        const live = {
            appId: "jZY9C3Ly",
            useSignature: true
        };

        return process.env.NODE_ENV === "production" ? live : dev;
    }

    get talkUseSignature() {
        return this.talkActiveSettings.useSignature;
    }

    get talkAppId() {
        return this.talkActiveSettings.appId;
    }

    get talkPrefix() {
        return this.context.getters.talkMeetingPrefix;
    }

    get talkUserInfo() {
        return this.talkUser;
    }

    get talkUnreads() {
        return this.talkUnreadMessages;
    }

    @Mutation
    public setTalkUser(data: Talk.User) {
        this.talkUser = data;
    }

    @Mutation
    public setTalkSession(data: Talk.Session) {
        this.talkSession = data;
    }

    @Mutation
    public setTalkUnreads(data: number) {
        this.talkUnreadMessages = data;
    }

    @Action({ rawError: true })
    public async setupTalk() {
        await Talk.ready;
        await this.context.dispatch("getOptions", ["talkOptions"]);

        if (!this.talkUser && this.talkPrefix) {
            const user = this.context.getters.userInfo
                ? this.context.getters.userInfo
                : false;

            if (user) {
                const talkId = `${this.talkPrefix}-${user.id}`;

                const talkUser = new Talk.User({
                    id: talkId,
                    name: user.name,
                    email: user.email ? user.email : null,
                    role: `${this.talkPrefix.toLowerCase()}_attendee`,
                    custom: {
                        companyName: user.companyName ? user.companyName : ""
                    }
                });

                this.context.commit("setTalkUser", talkUser);

                const sessionInput: TalkSessionInput = {
                    appId: this.talkAppId,
                    me: talkUser
                };

                if (this.talkUseSignature) {
                    sessionInput.signature = "test";
                    const token = this.context.rootGetters.idToken;

                    try {
                        const talkApiResponse = await getApiClient().get(
                            `${endpoint}`,
                            {
                                headers: {
                                    Authorization: `bearer ${token}`
                                }
                            }
                        );

                        sessionInput.signature = talkApiResponse.data
                            .talkSignature
                            ? talkApiResponse.data.talkSignature
                            : "";
                    } catch (error) {
                        throw new Error("Couldn't get Talk signature.");
                    }
                }

                const talkSession = new Talk.Session(sessionInput);

                talkSession.unreads.on("change", (conversationIds) => {
                    const amount = conversationIds.length;
                    this.context.commit("setTalkUnreads", amount);
                });

                this.context.commit("setTalkSession", talkSession);
            }
        } else if (!this.talkPrefix) {
            console.log("didn't attempt to set up talk; no meeting prefix set");
        }
    }
}
